import { render, staticRenderFns } from "./inventory.vue?vue&type=template&id=9fc8ac10&"
import script from "./inventory.vue?vue&type=script&lang=ts&"
export * from "./inventory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports